import type { Dispatch, SetStateAction } from 'react'
import React from 'react'
import { XStack, styled } from 'tamagui'
import { MainSheet } from '@centrito/ui/src/components/sheet/MainSheet'
import { BodyText3 } from '@centrito/ui/src/components/text/new/body/BodyText3'

export interface SheetTextButtonProps {
  text: string
  setIsOpen: Dispatch<SetStateAction<boolean>>
  isOpen: boolean
  textColor?: string
  Icon: React.ComponentType
  children: React.ReactNode
}

const Container = styled(XStack, {
  backgroundColor: '$samsamBlueTranslucent15',
  borderRadius: 8,
  alignItems: 'center',
  gap: 3,
})

export const SheetTextButton: React.FC<SheetTextButtonProps> = ({
  text,
  setIsOpen,
  isOpen,
  Icon,
  children,
  textColor = '$brightRed',
}) => {
  return (
    <React.Fragment>
      <MainSheet isOpen={isOpen} onOpenChange={setIsOpen}>
        {children}
      </MainSheet>
      <Container paddingHorizontal={8} paddingVertical={5} onPress={(): void => setIsOpen(true)}>
        <BodyText3 color={textColor} marginBottom={1} paddingLeft={3}>
          {text}
        </BodyText3>

        <Icon />
      </Container>
    </React.Fragment>
  )
}
