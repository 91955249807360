import React from 'react'
import { Sheet, type SheetProps } from 'tamagui'

export interface MainSheetProps {
  isOpen?: SheetProps['open']
  onOpenChange?: SheetProps['onOpenChange']
  snapPointsMode?: SheetProps['snapPointsMode']
  zIndex?: SheetProps['zIndex']
  snapPoints?: SheetProps['snapPoints']
  children: React.ReactNode
}

export const MainSheet: React.FC<MainSheetProps> = ({
  children,
  isOpen,
  onOpenChange,
  snapPointsMode,
  zIndex,
  snapPoints,
}) => {
  return (
    <Sheet
      modal
      open={isOpen}
      onOpenChange={onOpenChange}
      forceRemoveScrollEnabled={isOpen}
      dismissOnSnapToBottom
      snapPointsMode={snapPointsMode}
      zIndex={zIndex}
      snapPoints={snapPoints}
    >
      <Sheet.Overlay
        backgroundColor="$translucentBlack50"
        animation="lazy"
        enterStyle={{ opacity: 0 }}
        exitStyle={{ opacity: 0 }}
        zIndex={zIndex}
      />

      <Sheet.Handle />

      <Sheet.Frame
        maxWidth={500}
        width="$full"
        marginHorizontal="auto"
        backgroundColor="$pureWhite"
        zIndex={zIndex}
      >
        <Sheet.ScrollView>{children}</Sheet.ScrollView>
      </Sheet.Frame>
    </Sheet>
  )
}
