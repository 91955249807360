import React, { useContext, useEffect, useRef } from 'react'
import { AppStateContext } from '@centrito/app/provider/shopper/appState'
import ReactNativeNextJsScrollRestore from '@centrito/app/utils/scrollRestore'
import { ScrollView, type YStackProps } from '@centrito/ui/src'
import { MOBILE_WIDTH_STYLES } from '@centrito/ui/src/constants'

interface MainHomeLayoutProps {
  yStackProps?: Omit<YStackProps, 'children'>
  isHome?: boolean
  children: React.ReactNode
  backgroundColor?: string
}
const { useScroller } = ReactNativeNextJsScrollRestore

const MainHomeLayout: React.FC<MainHomeLayoutProps> = ({
  children,
  isHome = false,
  backgroundColor,
}) => {
  const scrollViewRef = useRef(null)

  const { onScroll } = useScroller(scrollViewRef, {
    // optional, in ms
    scrollDelay: 0,
  })

  const { isScrollingToTop, setIsScrollingToTop } = useContext(AppStateContext)

  useEffect(() => {
    if (isScrollingToTop && scrollViewRef.current) {
      ;(scrollViewRef.current as ScrollView).scrollTo({
        x: 0,
        y: 0,
        animated: false,
      })
      setIsScrollingToTop(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScrollingToTop])

  return (
    <ScrollView
      ref={scrollViewRef}
      onScroll={onScroll}
      stickyHeaderIndices={isHome ? [4] : undefined}
      {...MOBILE_WIDTH_STYLES}
      contentContainerStyle={{ flexGrow: 1 }}
      paddingBottom={50}
      scrollEventThrottle={16}
      showsVerticalScrollIndicator={false}
      backgroundColor={backgroundColor}
    >
      {children}
    </ScrollView>
  )
}

export default MainHomeLayout
