import React from 'react'
import { Text, XStack, YStack } from '@centrito/ui/src'
import { CaptionStandardText, ContentBoldText } from '@centrito/ui/src/components/text/new'
import { HomeValueProposalCard } from './Card'

export const HomeValueProposalCards: React.FC = () => {
  return (
    <XStack width="100%" gap={4} justifyContent="space-between" paddingHorizontal={15}>
      <HomeValueProposalCard
        Icon={<Text>🚚</Text>}
        Content={
          <YStack>
            <ContentBoldText color="#06264C">Envío gratis</ContentBoldText>
            <CaptionStandardText color="#06264C">a puntos Samsam</CaptionStandardText>
          </YStack>
        }
      />
      <HomeValueProposalCard
        Icon={<Text>💵</Text>}
        Content={
          <ContentBoldText color="#06264C" maxWidth="90%">
            Pagos contraentrega
          </ContentBoldText>
        }
      />
    </XStack>
  )
}
