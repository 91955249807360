import React from 'react'
import { BEAUTY_CATEGORY_NODE_PREFIX } from '@centrito/common/constants'
import PATHS from '@centrito/common/paths'
import type { BrandPublic } from '@centrito/api/nest/platform/database/domain/entities/brand'
import { ScrollView, Stack, XStack, YStack } from '@centrito/ui/src'
import { CircleFeedButton } from '@centrito/ui/src/components/button/CircleFeedButton'

interface BrandSelectorProps {
  isScrollView: boolean
  topElements: BrandPublic[]
  bottomElements: BrandPublic[]
  emptyElements: number
  showLabel: boolean
}

interface BaseSelectorProps {
  topElements: BrandPublic[]
  bottomElements: BrandPublic[]
  emptyElements: number
  showLabel: boolean
}

const BaseBrandSelector: React.FC<BaseSelectorProps> = ({
  topElements,
  bottomElements,
  emptyElements,
  showLabel,
}) => {
  return (
    <YStack paddingLeft={2}>
      <YStack>
        <XStack gap={8}>
          {topElements.map((topBrand) => (
            <CircleFeedButton
              hasBackground
              key={`brand-browser--${topBrand.name}`}
              href={{
                pathname: PATHS.Products.ListByBrand,
                query: {
                  brandId: topBrand.id,
                  categoryNodesPrefix: BEAUTY_CATEGORY_NODE_PREFIX,
                },
              }}
              imageSrc={topBrand.imageUrl}
              imageAlt={`${topBrand.name} logo`}
              label={topBrand.name}
              showLabel={showLabel}
              containerHeight={48}
            />
          ))}
        </XStack>
        <XStack gap={8}>
          {bottomElements.map((bottomElement) => (
            <CircleFeedButton
              hasBackground
              key={`brand-browser--${bottomElement.name}`}
              href={{
                pathname: PATHS.Products.ListByBrand,
                query: {
                  brandId: bottomElement.id,
                  categoryNodesPrefix: BEAUTY_CATEGORY_NODE_PREFIX,
                },
              }}
              imageSrc={bottomElement.imageUrl}
              imageAlt={`${bottomElement.name} logo`}
              label={bottomElement.name}
              showLabel={showLabel}
              containerHeight={48}
            />
          ))}
          {Array.from({ length: emptyElements }).map((_, index) => (
            <Stack key={`empty-element-${index}`} width={72} />
          ))}
        </XStack>
      </YStack>
    </YStack>
  )
}

export const BrandSelector: React.FC<BrandSelectorProps> = ({
  isScrollView,
  topElements,
  bottomElements,
  emptyElements,
  showLabel,
}) => {
  if (isScrollView) {
    return (
      <ScrollView
        alwaysBounceHorizontal={false}
        alwaysBounceVertical={false}
        bounces={false}
        overScrollMode="never"
        style={{ paddingBottom: 20 }}
      >
        <BaseBrandSelector
          topElements={topElements}
          bottomElements={bottomElements}
          emptyElements={emptyElements}
          showLabel={showLabel}
        />
      </ScrollView>
    )
  }

  return (
    <BaseBrandSelector
      topElements={topElements}
      bottomElements={bottomElements}
      emptyElements={emptyElements}
      showLabel={showLabel}
    />
  )
}
