import React from 'react'
import isNil from 'lodash.isnil'
import { SolitoImage as Image } from 'solito/image'
import { Link } from 'solito/link'
import { COUPON_BANNER_NAME } from '@centrito/common/constants'
import PATHS from '@centrito/common/paths'
import { trpc } from '@centrito/app/api/trpc'
import useWindowDimensions from '@centrito/app/utils/hooks/useWindowDimensions'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'
import { XStack } from '@centrito/ui/src'
import { CouponsBannerSkeleton } from '@centrito/ui/src/components/skeletons/CouponBannerSkeleton'

const PADDING_HORIZONTAL = 15

interface SubhomeProps {
  subhome: string
}

export const CouponBanner: React.FC<SubhomeProps> = ({ subhome }) => {
  const { data, isLoading } = trpc.catalog.homeBanner.findFirst.useQuery({
    bannerName: COUPON_BANNER_NAME,
  })
  const { width } = useWindowDimensions()

  if (isNil(data)) {
    return null
  }

  const { alt, imageUrl } = data

  const adjustedWidth = width - PADDING_HORIZONTAL * 2
  const height = adjustedWidth * 0.12

  if (isLoading) {
    return (
      <XStack flex={1} paddingHorizontal={PADDING_HORIZONTAL} paddingVertical={10}>
        <CouponsBannerSkeleton />
      </XStack>
    )
  }

  return (
    <XStack
      alignItems="center"
      justifyContent="center"
      flex={1}
      width={width}
      paddingHorizontal={PADDING_HORIZONTAL}
      paddingVertical={10}
      onPress={(): void => {
        posthogClient.captureCustomEvent('shopper_subhome_coupon_banner_pressed', {
          subhome,
        })
      }}
    >
      <Link href={PATHS.Account.Coupons}>
        <Image
          contentFit="fill"
          style={{ borderRadius: 8 }}
          src={imageUrl}
          alt={alt}
          width={adjustedWidth}
          height={height}
        />
      </Link>
    </XStack>
  )
}
