import React from 'react'
import { SolitoImage as Image } from 'solito/image'
import { Link } from 'solito/link'
import PATHS from '@centrito/common/paths'
import { YStack } from '@centrito/ui/src'
import { DetailText2 } from '@centrito/ui/src/components/text/new/detail/DetailText2'

export const CircleFeedOutletsButton: React.FC = () => {
  return (
    <Link href={PATHS.Products.Outlets}>
      <YStack width={72} height={80} gap={5} alignItems="center">
        <Image
          src="https://odcmbzocropvzmbsarer.supabase.co/storage/v1/object/public/product-images/OUTLETS_GIF.gif"
          alt="OUTLETS"
          width={48}
          height={48}
          style={{ borderRadius: 24 }}
          unoptimized
        />
        <DetailText2
          lineHeight={13}
          textAlign="center"
          fontSize={9}
          color="$primary1000"
          backgroundColor="$amber"
          paddingHorizontal={3}
          paddingVertical={1}
          borderTopLeftRadius={8}
          borderBottomLeftRadius={2}
          borderTopRightRadius={2}
          borderBottomRightRadius={8}
          fontWeight="400"
        >
          OUTLETS
        </DetailText2>
      </YStack>
    </Link>
  )
}
