import React, { useEffect, useRef, useState } from 'react'
import { ChevronLeft, ChevronRight } from '@tamagui/lucide-icons'
import isNil from 'lodash.isnil'
import { FlatList } from 'react-native'
import { Pressable } from 'react-native'
import { SolitoImage as Image } from 'solito/image'
import { IMAGE_CODE_FROM_PNG_PIXEL_GRAY } from '@centrito/common/constants'
import useWindowDimensions from '@centrito/app/utils/hooks/useWindowDimensions'
import { Circle, Text, XStack, YStack, debounce, useDebounceValue } from '@centrito/ui/src'

export type ImageSource = {
  url: string
  urlWebP?: string | null
  alt: string
  href?: string | null
}

interface BaseImageSliderProps {
  automaticScrollTime?: number
  width?: number
  gap?: number
  hasLargeImages?: boolean
  margin?: number
  borderRadius?: number
  hasCounterDots?: boolean
  imageSources: ImageSource[]
  imageIndex: number
  setImageIndex:
    | React.Dispatch<React.SetStateAction<number>>
    | ((v: number | ((v: number) => number)) => void)
  nextImage: () => void
  previousImage: () => void
  aspectRatio?: number
  isMarginTopDisabled?: boolean
  isProductPage?: boolean
  windowHeight?: number
  onPress?: (image: ImageSource, index: number) => void
  DiscountTag?: React.ReactNode
  isLegacy?: boolean
}

export const BaseImageSlider: React.FC<BaseImageSliderProps> = ({
  automaticScrollTime = 0,
  borderRadius = 0,
  width,
  hasCounterDots = true,
  gap = 0,
  margin = 0,
  hasLargeImages = false,
  aspectRatio = 1,
  imageIndex,
  nextImage,
  previousImage,
  setImageIndex,
  imageSources,
  isMarginTopDisabled = false,
  isProductPage = false,
  windowHeight,
  onPress,
  DiscountTag = null,
}): JSX.Element => {
  const scrollViewRef = useRef<FlatList>(null)
  const { width: _windowWidth } = useWindowDimensions()
  const windowWidth = !isNil(width) ? width : _windowWidth - margin * 2
  const buttonShadowProps = {
    elevation: 10,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 10,
    shadowColor: '#0000001E',
  }

  const debounceIndex = useDebounceValue(imageIndex, automaticScrollTime ? 0 : 150)

  const scrollToIndex = (newIndex: number): void => {
    const currentRef = scrollViewRef.current
    if (currentRef) {
      currentRef.scrollToOffset({
        offset: newIndex * windowWidth,
        animated: true,
      })
    }
  }

  useEffect((): void => {
    scrollToIndex(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSources])

  // sync index with flatlist scroll
  useEffect(() => {
    scrollToIndex(debounceIndex)
  }, [debounceIndex])

  useEffect((): (() => void) | void => {
    if (automaticScrollTime) {
      const timeoutId = setTimeout(() => {
        if (debounceIndex < imageSources.length - 1 || debounceIndex === 0) {
          return nextImage()
        }

        setImageIndex(0)
      }, automaticScrollTime)
      return () => clearTimeout(timeoutId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageSources])

  const fixedAspectRatio = hasLargeImages ? 2 / 3 : 1
  const calculatedHeight = windowWidth / fixedAspectRatio
  const maxHeight = windowHeight ? windowHeight * 0.68 : null

  return (
    <YStack
      width="100%"
      margin={margin}
      marginTop={isMarginTopDisabled ? 0 : margin}
      {...(!isProductPage && { maxWidth: windowWidth, maxHeight: calculatedHeight })}
    >
      <XStack
        width="100%"
        {...(!isProductPage && { height: windowWidth / aspectRatio })}
        maxHeight={maxHeight}
        position="relative"
        zIndex={0}
      >
        {imageIndex < imageSources.length - 1 && (
          <XStack
            position="absolute"
            zIndex={998}
            top="42%"
            right={16}
            justifyContent="center"
            onPress={(): void => {
              nextImage()
            }}
          >
            <Circle
              size={34}
              backgroundColor="#FAFAFA"
              display="flex"
              alignItems="center"
              justifyContent="center"
              {...buttonShadowProps}
            >
              <ChevronRight color="#011627" size={24} />
            </Circle>
          </XStack>
        )}
        {imageIndex > 0 && (
          <XStack
            position="absolute"
            zIndex={998}
            top="42%"
            left={16}
            justifyContent="center"
            onPress={(): void => {
              previousImage()
            }}
          >
            <Circle
              size={34}
              backgroundColor="#FAFAFA"
              display="flex"
              alignItems="center"
              justifyContent="center"
              {...buttonShadowProps}
            >
              <ChevronLeft color="#011627" size={24} />
            </Circle>
          </XStack>
        )}
        {!isNil(DiscountTag) && (
          <XStack position="absolute" bottom={16} left={16} zIndex={998}>
            {DiscountTag}
          </XStack>
        )}
        <XStack width="100%" height="100%" display="block">
          {hasCounterDots && (
            <XStack
              position="absolute"
              bottom={16}
              right={16}
              zIndex={99}
              backgroundColor="#0C0C0C3D"
              paddingHorizontal={9}
              paddingVertical={2}
              borderRadius={10}
            >
              <Text fontFamily="$poppinsFont" color="#FAFAFA" fontSize={15}>
                {imageIndex + 1}/{imageSources.length}
              </Text>
            </XStack>
          )}
          <XStack animation="quick" position="relative" height="100%" gap={gap}>
            <FlatList
              horizontal
              pagingEnabled
              showsHorizontalScrollIndicator={false}
              disableIntervalMomentum
              data={imageSources}
              keyExtractor={(item, index): string => `${index}-${item.alt}`}
              ref={scrollViewRef}
              decelerationRate="fast"
              renderItem={({
                item: image,
                index,
              }: {
                item: ImageSource
                index: number
              }): JSX.Element => {
                return (
                  <Pressable
                    onPress={(): void => {
                      onPress?.(image, index)
                    }}
                  >
                    <Image
                      src={image.urlWebP ? image.urlWebP : image.url}
                      alt={image.alt}
                      contentFit={isProductPage ? 'contain' : 'fill'}
                      placeholder="blur"
                      blurDataURL={IMAGE_CODE_FROM_PNG_PIXEL_GRAY}
                      style={{
                        borderRadius,
                        maxHeight: isProductPage ? calculatedHeight : windowHeight,
                      }}
                      height={isProductPage ? calculatedHeight : windowWidth / aspectRatio}
                      width={windowWidth}
                    />
                  </Pressable>
                )
              }}
            />
          </XStack>
        </XStack>
      </XStack>
    </YStack>
  )
}
